import { SupportedChainId } from 'constants/chains'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { isAddress } from 'utils'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import { NATIVE_CHAIN_ID } from '../../constants/tokens'

type Network = 'ethereum' | 'arbitrum' | 'optimism' | 'polygon' | 'smartchain'

export function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    // case SupportedChainId.MAINNET:
    //   return 'ethereum'
    case SupportedChainId.ARBITRUM_ONE:
      return 'arbitrum'
    // case SupportedChainId.OPTIMISM:
    //   return 'optimism'
    // case SupportedChainId.POLYGON:
    //   return 'polygon'
    // case SupportedChainId.BNB:
    //   return 'smartchain'
    default:
      return 'ethereum'
  }
}

export function getNativeLogoURI(): string {
  return EthereumLogo
}

function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.ARBITRUM_ONE): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [
    SupportedChainId.ARBITRUM_ONE,
    // SupportedChainId.MAINNET,
    // SupportedChainId.OPTIMISM,
    // SupportedChainId.BNB,
  ]
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  }

  // Celo logo logo is hosted elsewhere.
  // if (isCelo(chainId)) {
  //   if (address === nativeOnChain(chainId).wrapped.address) {
  //     return 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_CELO.png'
  //   }
  // }
}

export default function useCurrencyLogoURIs(
  currency:
    | {
        isNative?: boolean
        isToken?: boolean
        address?: string
        chainId: number
        logoURI?: string | null
      }
    | null
    | undefined
): string[] {
  const locations = useHttpLocations(currency?.logoURI)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative || currency.address === NATIVE_CHAIN_ID) {
        logoURIs.push(getNativeLogoURI())
      } else if (currency.isToken || currency.address) {
        const checksummedAddress = isAddress(currency.address)
        const logoURI = checksummedAddress && getTokenLogoURI(checksummedAddress, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
