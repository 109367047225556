import { TransactionReceipt } from '@ethersproject/abstract-provider'
import { SupportedChainId } from 'constants/chains'
// import useBlockNumber, { useFastForwardBlockNumber } from 'lib/hooks/useBlockNumber'
import ms from 'ms.macro'
import { useCallback, useEffect } from 'react'
import { useRemoveTransaction } from 'state/transactions/hooks'
import { TransactionDetails, TransactionInfo } from 'state/transactions/types'
import { retry, RetryableError, RetryOptions } from 'utils/retry'
// import { TransactionReceipt } from 'viem'
import { useChainId, useClient } from 'wagmi'
import { useEthersProvider } from 'wagmi-lib/adapters'

import useBlockNumber, { useFastForwardBlockNumber } from '../useBlockNumber'

interface Transaction {
  addedTime: number
  receipt?: unknown
  lastCheckedBlockNumber?: number
}

export function shouldCheck(lastBlockNumber: number, tx: Transaction): boolean {
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / ms`1m`
  if (minutesPending > 60) {
    // every 10 blocks if pending longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending longer than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

const RETRY_OPTIONS_BY_CHAIN_ID: { [chainId: number]: RetryOptions } = {
  [SupportedChainId.ARBITRUM_ONE]: { n: 10, minWait: 250, maxWait: 1000 },
  [SupportedChainId.BASE]: { n: 10, minWait: 250, maxWait: 1000 },
  // [SupportedChainId.ARBITRUM_GOERLI]: { n: 10, minWait: 250, maxWait: 1000 },
  // [SupportedChainId.OPTIMISM]: { n: 10, minWait: 250, maxWait: 1000 },
  // [SupportedChainId.OPTIMISM_GOERLI]: { n: 10, minWait: 250, maxWait: 1000 },
}
const DEFAULT_RETRY_OPTIONS: RetryOptions = { n: 1, minWait: 0, maxWait: 0 }

interface UpdaterProps {
  pendingTransactions: { [hash: string]: TransactionDetails }
  onCheck: (tx: { chainId: number; hash: string; blockNumber: number }) => void
  onReceipt: (tx: {
    chainId: number
    hash: string
    receipt: TransactionReceipt
    transactionInfo: TransactionInfo
  }) => void
}

export default function Updater({ pendingTransactions, onCheck, onReceipt }: UpdaterProps): null {
  const chainId = useChainId()
  const provider = useEthersProvider({ chainId })

  const lastBlockNumber = useBlockNumber()
  const fastForwardBlockNumber = useFastForwardBlockNumber()
  const removeTransaction = useRemoveTransaction()
  const client = useClient({ chainId })

  const getReceipt = useCallback(
    (hash: string) => {
      if (!provider || !chainId) throw new Error('No provider or chainId')
      const retryOptions = RETRY_OPTIONS_BY_CHAIN_ID[chainId] ?? DEFAULT_RETRY_OPTIONS
      return retry(
        () =>
          provider.getTransactionReceipt(hash).then((receipt) => {
            if (receipt === null) {
              if (new Date().getTime() - pendingTransactions[hash].addedTime > 30000) {
                removeTransaction(chainId, hash)
                return
              }
              console.debug(`Retrying tranasaction receipt for ${hash}`)
              throw new RetryableError()
            }
            return receipt
          }),
        retryOptions
      )
      // return retry(
      //   () =>
      //     client
      //       .request({
      //         method: 'eth_getTransactionReceipt',
      //         params: [hash as any],
      //       })
      //       .then((receipt) => {
      //         if (receipt === null) {
      //           if (new Date().getTime() - pendingTransactions[hash].addedTime > 30000) {
      //             removeTransaction(chainId, hash)
      //             return
      //           }
      //           console.debug(`Retrying tranasaction receipt for ${hash}`)
      //           throw new RetryableError()
      //         }
      //         return formatTransactionReceipt(receipt)
      //       }),
      //   retryOptions
      // )
    },
    [chainId, provider, removeTransaction, pendingTransactions]
  )

  const getTransaction = useCallback(
    (tx: TransactionDetails) => {
      if (!provider || !chainId) throw new Error('No provider or chainId')
      // return provider.getTransaction(tx.hash).then((response) => {
      //   if (!response) {
      //     removeTransaction(chainId, tx.hash)
      //   }
      // })
    },
    [chainId, provider, removeTransaction]
  )

  useEffect(() => {
    if (!chainId || !provider || !lastBlockNumber) return

    const cancels = Object.keys(pendingTransactions)
      .filter((hash) => shouldCheck(lastBlockNumber, pendingTransactions[hash]))
      .map((hash) => {
        const { promise, cancel } = getReceipt(hash)
        promise
          .then((receipt) => {
            if (receipt) {
              fastForwardBlockNumber(Number(receipt.blockNumber))
              onReceipt({ chainId, hash, receipt, transactionInfo: pendingTransactions[hash].info })
            } else {
              onCheck({ chainId, hash, blockNumber: lastBlockNumber })
            }
          })
          .catch((error) => {
            if (!error.isCancelledError) {
              console.warn(`Failed to get transaction receipt for ${hash}`, error)
            }
          })
        return cancel
      })

    Object.values(pendingTransactions).forEach((tx) => {
      getTransaction(tx)
    })

    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [
    chainId,
    provider,
    lastBlockNumber,
    getReceipt,
    onReceipt,
    onCheck,
    pendingTransactions,
    fastForwardBlockNumber,
    getTransaction,
  ])

  return null
}
