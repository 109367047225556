export enum TokenStatus {
    // HIGHER = 'New',
    // BUILD = 'New',
    // KEYCAT = 'New',
    // SPEC = '🔥',
    // OKAYEG = '🔥',
    // CHOMP = 'New',
    // PRIME = 'New', 
    // GROOVE = 'New', 
    // COSMIC = 'New', 
    // BRETT = 'New', 
    // SKOP = 'New', 
    // NORMIE = 'New', 
    // CARLO = '🔥', 
    // BTCB = 'New', 
    // SHOG = 'New'
    NZT = '🔥'

  }

export type TokenStatusKey = keyof typeof TokenStatus